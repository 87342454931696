<template>
  <div>
    <v-row><v-col><certificate-header /></v-col></v-row>
    <v-row><v-col> <widget-line /></v-col></v-row>
    <v-row><v-col> <certificate-filter /></v-col></v-row>
    <v-row v-if="totalCount > 0">
      <v-col>  <certificate-table /></v-col>
    </v-row>
    <base-empty-block-page
      v-else
      title="Здесь пока ничего нет :’("
    >
      <template v-slot:description>
        Здесь отображается информация по заказам ваших сертификатов. По текущим настройкам фильтра или периода данных нет.
      </template>
      <template v-slot:image>
        <v-img
          src="@/assets/png/settings-dummy.png"
          width="186px"
          height="156px"
        />
      </template>
    </base-empty-block-page>
  </div>
</template>

<script>
  import CertificateTable from './CertificateTable'
  import CertificateFilter from './Filter'
  import CertificateHeader from './Header'
  import DummyScreen from '@/views/loyalty/DummyScreen'
  import WidgetLine from '@/views/account/certificate/widget/index'

  export default {
    components: {
      CertificateFilter,
      CertificateTable,
      CertificateHeader,
      DummyScreen,
      WidgetLine,
    },
    props: {
    },
    data () {
      return {}
    },
    computed: {
      loadingRequest () {
        return this.$store.getters['template/shared/loadingRequest']
      },
      loadingApp () {
        return this.$store.getters['template/shared/loadingApp']
      },
      programs () {
        return this.$store.getters['company/program/programs']
      },
      program () {
        return this.$store.getters['company/program/program']
      },
      period () {
        return this.$store.getters['reference/date_selection/period']
      },
      filter () {
        return this.$store.getters['account/certificate/filter/filter']
      },
      totalCount () {
        return this.$store.getters['account/certificate/certificate/totalCount']
      },
    },
    watch: {
      'program.id' (v) {
        if (v) {
          this.fetchData()
        }
      },
      filter (v) {
        if (v) {
          this.fetchData()
        }
      },
      period (v) {
        if (v) {
          this.fetchData()
        }
      },
    },
    async created () {
      await this.fetchData()
    },
    methods: {
      async fetchData () {
        console.log('fetchData ALL')
        console.log(this.period)
        try {
          if (this.program.id) {
            this.loading = true
            const widget = {
              program_id: this.program.id,
              start_period: this.$moment.utc(this.period.start).format('YYYY-MM-DD'),
              end_period: this.$moment.utc(this.period.end).format('YYYY-MM-DD'),
              filter: this.filter,
            }
            await this.$store.dispatch('account/certificate/certificate/list', widget)
            await this.$store.dispatch('account/certificate/certificate/payments', widget)
            await this.$store.dispatch('account/certificate/certificate/periodData', widget)
          }
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>

<style scoped>

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"plus-table",attrs:{"headers":_vm.headers,"items":_vm.filtered_certificates,"single-expand":true,"options":_vm.tableSettings,"expanded":_vm.expanded,"item-class":function () { return 'clickable-row'; },"item-key":"id","show-expand":false,"hide-default-footer":"","server-items-length":_vm.totalCount},on:{"update:options":function($event){_vm.tableSettings=$event},"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.details,"update:sort-by":function($event){return _vm.fetchData()},"update:sort-desc":function($event){return _vm.fetchData()}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" More info about "+_vm._s(item.id)+" ")])]}},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('span',{staticClass:"iconify",attrs:{"data-icon":"bi:chevron-right","data-inline":"false"},on:{"click":function($event){return expand(!isExpanded)}}})]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.number || '-'))])]}},{key:"item.certificate.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"td-padding-wrapper"},[_c('div',{staticClass:"td-content-main"},[_vm._v(" "+_vm._s(item.certificate.name)+", "+_vm._s(item.nominal_name_full || '-')+" ")]),(item.order)?_c('div',{staticClass:"hint",staticStyle:{"color":"#4776E6"}},[_vm._v(" "+_vm._s(item.order.num)+" ")]):_vm._e()])]}},{key:"item.user.UserName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"avatar"},[_c('img',{attrs:{"src":item.user.avatar}})]),_c('div',{staticClass:"td-content-wrapper"},[_c('div',{staticClass:"td-content-main"},[_vm._v(" "+_vm._s(item.user.UserName)+" ")]),(item.user.last_activity)?_c('div',{staticClass:"hint",staticStyle:{"color":"#9191A1"}},[_vm._v(" Был(а) в сети "+_vm._s(_vm.$moment.utc(item.user.last_activity).local().format('DD.MM.YYYY\u00A0HH:mm'))+" ")]):_vm._e()])]}},{key:"item.nominal.selling_price",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"float":"right"}},[_vm._v(" "+_vm._s(item && item.nominal && item.nominal.selling_price ? _vm.formatNum(item.nominal.selling_price) : _vm.formatNum(item.price))+" ₽ ")])]}},{key:"item.payment_status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"status-icon",attrs:{"align":"center","height":"21","width":"21","src":_vm.paymentStatusIcon(item.payment_status)}},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.paymentStatusTooltip(item.payment_status, item.status)))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"status-icon",attrs:{"align":"center","height":"21","width":"21","src":_vm.statusIcon(item.status)}},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.statusTooltip(item.status)))])])]}},{key:"item.merchant_order_status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"status-icon",attrs:{"align":"center","height":"21","width":"21","src":_vm.merchantOrderStatusIcon(item.merchant_order_status)}},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.merchantOrderStatusTooltip(item.merchant_order_status)))])])]}},{key:"item.date_issued",fn:function(ref){
var item = ref.item;
return [_c('date-column',{attrs:{"value":item.date_issued}})]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('date-column',{attrs:{"value":item.created_at}})]}},{key:"item.used_at",fn:function(ref){
var item = ref.item;
return [_c('date-column',{attrs:{"value":item.used_at}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","loading":item.loading}},on),[_c('v-icon',[_vm._v("$iconify_feather-more-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.getCertActions(item)),function(menuItem,index){return _c('v-list-item',{key:index,on:{"click":function($event){return menuItem.action(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"neutral-500"}},[_vm._v(" "+_vm._s(menuItem.icon)+" ")])],1),_c('v-list-item-title',{class:{
                    'body-s-medium' : true,
                    'neutral-500--text':true,
                  }},[_vm._v(" "+_vm._s(menuItem.title)+" ")])],1)}),1)],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"pagination",attrs:{"align":"center"}},[_c('v-col',[_c('div',{staticClass:"table-pagination-block"},[_c('div',{staticStyle:{"margin-right":"20px"}},[_vm._v(" Всего "+_vm._s(_vm.totalCount)+" "+_vm._s(_vm.getWord(_vm.totalCount, _vm.wordOperations))+" на "+_vm._s(_vm.pagesCount)+" "+_vm._s(_vm.getWord(_vm.pagesCount, _vm.wordPages))+" ")]),_c('select-page-limit',{attrs:{"min-width":"200px","items":_vm.paginationOptions,"model":_vm.tableSettings.itemsPerPage,"item-value":"value","item-label":"text"},on:{"update:model":function($event){return _vm.$set(_vm.tableSettings, "itemsPerPage", $event)}}}),_c('div',{staticClass:"app__spacer"}),_c('div',{staticClass:"text-center"},[_c('v-pagination',{staticStyle:{"min-width":"400px"},attrs:{"next-icon":"fas fa-chevron-right","prev-icon":"fas fa-chevron-left","length":_vm.pagesCount,"total-visible":7,"circle":""},model:{value:(_vm.tableSettings.page),callback:function ($$v) {_vm.$set(_vm.tableSettings, "page", $$v)},expression:"tableSettings.page"}})],1)],1)])],1),_c('certificate-form',{attrs:{"detailed-cert":_vm.detailedCert},model:{value:(_vm.showDetails),callback:function ($$v) {_vm.showDetails=$$v},expression:"showDetails"}}),(_vm.paidDialog)?_c('certificate-paid-dialog',{attrs:{"cert":_vm.detailedCert},model:{value:(_vm.paidDialog),callback:function ($$v) {_vm.paidDialog=$$v},expression:"paidDialog"}}):_vm._e(),(_vm.usedDialog)?_c('certificate-used-dialog',{attrs:{"cert":_vm.detailedCert},model:{value:(_vm.usedDialog),callback:function ($$v) {_vm.usedDialog=$$v},expression:"usedDialog"}}):_vm._e(),(_vm.continueDialog)?_c('certificate-continue-dialog',{attrs:{"cert":_vm.detailedCert},model:{value:(_vm.continueDialog),callback:function ($$v) {_vm.continueDialog=$$v},expression:"continueDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }